import PageLayout from "../../components/PageLayout/PageLayout";
import Button from "../../components/Button/Button";
import "./Logout.scss";
import { ReactComponent as Icon } from "./img/back.svg";
import { useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

  return (
    <PageLayout clsName="logout">
      <div className="Logout">
       <div className="content">
        <div className="icon">
        <Icon/>
        </div>
       <h2 className="title">
       Poprawne <br/>wylogowanie
       </h2>
       <p className="desc">
        Czas na chwilę przerwy?<br/>
        Wróć do nas niedługo.
        </p>
        <Button onClick={ () =>  navigate("/login")}>Zaloguj się ponownie</Button>
      </div>
       </div>
    </PageLayout>
  );
}

export default Logout;