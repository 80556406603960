import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./SalesOutlets.scss";
import { ChartType, DateType } from "../Dashboard/DashboardBarChart";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "../../hooks";
import { GetStatsDay, GetStatsMonth, GetStatsYear } from "../../features/stats/statsService";
import dayjs from "dayjs";
import { ReactComponent as Arrow } from "./img/arrow.svg";
import { ReactComponent as ArrowNext } from "./img/arrow-next.svg";
import gridBlue from './img/grid-blue.svg';
import gridWhite from './img/grid-white.svg';
import rowsBlue from './img/rows-blue.svg';
import rowsWhite from './img/rows-white.svg';
import { selectShops } from "../../features/stats/statsSlice";
import { selectTerritories } from "../../features/user/userSlice";
import { Link } from "react-router-dom";

interface DateSelectProps {
  dateCounter: number,
  dateType: DateType,
  setDateCounter: any
}

function DateSelect({dateCounter, dateType, setDateCounter}:DateSelectProps) {

  const [select, setSelect] = useState(<></>)

  useEffect(()=>{
    switch(dateType) {
      case 'week':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'week').startOf('week').format('DD.MM')+' - '+dayjs().add(dateCounter, 'week').endOf('week').format('DD.MM') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'month':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().locale('pl').add(dateCounter, 'month').format('MMMM YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'year':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'year').format('YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'full':
        setSelect(
          <div className="DateSelect">
            <div className="date-range">2021 - {dayjs().format('YYYY')}</div>
          </div>
        )
      break;
    }
  },[dateCounter, dateType])
  return (
    <>
      { select }
    </>
  )
}

interface GridBoxProps {
  shop: any,
  address: any
}

function GridBox({shop, address}:GridBoxProps) {

  return (
    <div className="grid-box">
      <div className="grid-box-header">
        <p className="header">{ shop.popid }</p>
          <Link to={"/statystyki/"+shop.popid.toLowerCase()}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14" cy="14" r="14" fill="#001854"/>
              <path d="M13 18L17 14L13 10" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </Link>
        </div>
      <div className="gird-box-body">
        <div className="row">
          <p>Liczba sprzedawców</p>
          <strong>{ shop?.clerks != null ? shop?.clerks : 0 }</strong>
        </div>
        <div className="row">
          <p>Sprzedanych urządzeń</p>
          <strong>{ shop.device }</strong>
        </div>
        <div className="row">
          <p>Poleconych konsumentów</p>
          <strong>{ shop.referral }</strong>
        </div>
      </div>
      <div className="gird-box-footer">
        <p>{ address }</p>
      </div>
    </div>
  )
}

function RowCard({shop, address}:GridBoxProps) {
  const {width} = useWindowDimensions();
  const [dropDown, setDropDown] = useState(false)
  function handleDropDown() {
    
  }

  return (
    <div className="row-card" style={dropDown ? {marginBottom: "100px"} : {}}>
      {width > 1020 ? 
      <>
        <div className="header">
          <span>{ shop.popid }</span>
        </div>
        <p>{ shop?.clerks}</p>
        <p>{ shop.device }</p>
        <p>{ shop.referral }</p>
        <p className="adress">{ address }</p>
        <Link to={"/statystyki/"+shop.popid.toLowerCase()}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill="#001854"/>
            <path d="M13 18L17 14L13 10" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Link>
      </>
      :
      <>
        <div className="header">
          <span>{ shop.popid }</span>
        </div>
        <p className="adress">{ address }</p>
        <Link to={"/statystyki/"+shop.popid.toLowerCase()}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill="#001854"/>
            <path d="M13 18L17 14L13 10" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </Link>
        <div className="drop-down" style={dropDown ? {bottom: "-78px"} : {}}>
            <div className="drop-down-btn" onClick={() => setDropDown(!dropDown)}>
              {!dropDown ? 
              <span>
                Więcej informacji
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7.5L8 10.5L11 7.5" stroke="#001854" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </span>
              : 
              <span>
                Zwiń informacje
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5 10.5L8.5 7.5L5.5 10.5" stroke="#001854" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </span>}
            </div>
          {dropDown && <div className="drop-down-active">
            <div className="row">
              <p>Liczba sprzedawców</p>
              <p className="strong">{ shop?.clerks}</p>
            </div>
            <div className="row">
              <p>Liczba Sprzedanych urządzeń</p>
              <p className="strong">{ shop.device }</p>
            </div>
            <div className="row">
              <p>Poleconych konsumentów</p>
              <p className="strong">{ shop.referral }</p>
            </div>
          </div>}
        </div>
      </>}
    </div>
  )
}

function SalesOutlets() {
  const [rankingType, setRankingType] = useState<ChartType>("device")
  const [dateType, setDateType] = useState<DateType>("week");
  const dispatch = useAppDispatch();
  const [cardLayout, setCardLayout] = useState(true)
  const [dateCounter, setDateCounter] = useState(0);
  const shops = useAppSelector(selectShops);
  const [search, setSearch] = useState('');
  const territories = useAppSelector(selectTerritories);
  const {width} = useWindowDimensions();
  
  useEffect(()=>{
    if (dateType === 'week') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'week').startOf('week').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'week').endOf('week').format('YYYY-MM-DD')}))
    } else if (dateType === 'month') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'month').startOf('month').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'month').endOf('month').format('YYYY-MM-DD')}))
    } else if (dateType === 'year') {
      dispatch(GetStatsMonth({from: dayjs().add(dateCounter, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'year').endOf('year').format('YYYY-MM-DD')}))
    } else if (dateType === 'full') {
      dispatch(GetStatsYear({from: dayjs().add(-1, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().endOf('year').format('YYYY-MM-DD')}))
    }
  }, [dateCounter, dateType])

  return (
    <PageLayout>
      <div className="page sales-outlets">
        <div className="sales-nav-container">
          <h1>Punkty sprzedaży</h1>
          <div className="sales-nav">
            <input className="search" placeholder="Wpisz adres lub kod punktu..." onChange={(e)=>setSearch(e.target.value)}></input>
            <img onClick={() => setCardLayout(!cardLayout)} src={cardLayout ? rowsWhite : rowsBlue} alt="" />
            <img onClick={() => setCardLayout(!cardLayout)} src={!cardLayout ? gridWhite : gridBlue } alt="" />
          </div>
        </div>

        <div className="dropshadow-box">
          <div className="head-container">

            <div className="time-container">
              <DropDownTabs
                handleChangeTab={(val: any) => {setDateType(val); setDateCounter(0)}}
                activeTab={dateType}
                tabList={[{name: "Tydzień", value: "week"}, {name: "Miesiąc", value: "month"}]}
              />
              <DateSelect dateCounter={dateCounter} dateType={dateType} setDateCounter={setDateCounter} />
            </div>
          </div>
          
          <div className="sales-body grid rows">
          {cardLayout ? null : <div className="row-caption row-caption-desktop"><span>Kod punktu</span><span>Liczba sprzedawców</span><span>Sprzedanych urządzeń</span><span>Poleconych konsumentów</span><span>Adres</span></div>}
          <hr />
          {cardLayout ? null : <div className="row-caption row-caption-mobile"><span>Kod punktu</span><span>Adres</span></div>}

            <div className={cardLayout ? "grid" : "rows"}>
              { shops.filter((shop:any,key:number) => {
                if (search) {
                  const territory = territories.filter((item:any)=>item.popid === shop.popid)[0]
                  const address = territory?.street+', '+territory?.postalCode+', '+territory?.cityName
                  if (  shop.popid.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    return true;
                  } else if ( territory && address.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (shop.popid === '') {
                  return false;
                } else {
                  return true;
                }
              }).map((sh:any) =>{
                const territory = territories.filter((item:any)=>item.popid === sh.popid)[0]
                const address = <><span>{territory?.street+', '}</span><span>{territory?.postalCode+', '}{territory?.cityName}</span></>
                return cardLayout ? <GridBox shop={sh} address={address} /> : <RowCard shop={sh} address={address} />
              })}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default SalesOutlets;