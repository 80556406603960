import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, appFetch, fileFetch } from "../../fetch";
import type { RootState } from "../../store";
import { showDrawer } from "../ui/uiSlice";

const headers = {
  "Content-Type": "application/json",
};

interface UserDetailsArgs {
  navigate: any,
  isRedirect: boolean,
  token: string
}

export const UserDetails = createAsyncThunk<any, UserDetailsArgs>(
  "auth/UserDetails", 
  async ({navigate, isRedirect, token}, {dispatch, getState}) => {
    const state = getState() as RootState;

    return appFetch("POST", API_URL, "/representative/details", false, token ? token : state.user.token.uuid, (result: any) => {
      if(result.status && result.status.success) {
        if(isRedirect) {
          navigate && navigate("/dashboard", {replace: true})
        }
        return result.data;
      } else {
        localStorage.removeItem("token");
        if (isRedirect) { 
          navigate("/", {replace: true});
        } else {
          window.location.replace('/')
        }
      }
    }, (error: any) => {

    })

});

interface LoginArgs {
  navigate: any,
  credentials: {
    emailAddress: string,
    password: string
  }
}

export const Login = createAsyncThunk<any, LoginArgs>(
  "user/Login",
  async ({credentials, navigate}, {dispatch, rejectWithValue}) => {
    try {
      return appFetch("POST", API_URL, "/representative/login", {representative: credentials}, false, (result: any) => {
        if(result.status && result.status.success) {
            localStorage.setItem("token", JSON.stringify(result.data.token));
            dispatch(UserDetails({navigate: navigate, isRedirect: false, token: result.data.token.uuid}))
            navigate("/dashboard", {replace: true})
            return result.data.token;
        } else {
          dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
        }
      }, (error: any) => {
        dispatch(showDrawer({type: 'error', data: {name: 'generic', errorCode: ""} }))
      })
    } catch (err: any) {
      // Custom error message from Server returned to reducer using rejectWithValue
      // This error is available in action.payload unlike action.error for unhandled errors
      return rejectWithValue(err.response.data);
    }
  }
);

interface RegisterArgs {
  data: {
    firstName: string,
    lastName: string,
    salesPoint: string,
    emailAddress: string,
    mobilePhone: string,
    hostessCode: string,
    ageVerified: boolean
    consentAgreement: boolean,
    marketingConsent: boolean,
    phoneContact: string,
    emailContact: string,
  }
}

export const Register = createAsyncThunk<any, RegisterArgs>(
  "user/Register",
  async ({data}, { dispatch, getState}) => {
    const state = getState() as RootState;
    appFetch("POST", API_URL, "/owner/register", {owner: data,
      reset: {
          return: {
              url: "https://"+window.location.hostname+"/nowe-haslo/?reset="
          }
      }}, false, (result: any) => {
        if(result.status && result.status.success) {
          return true;
        } else {
          dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
          return false;
        }
      }, (error: any) => {
        dispatch(showDrawer({type: 'error', data: {name: 'generic', errorCode: ""} }))
      })
    }
);

interface LogoutArgs {
  navigate: any
}

export const Logout = createAsyncThunk<any, LogoutArgs>("user/Logout", 
  async ({navigate}, {dispatch, getState}) => {
    const state = getState() as RootState;
    return appFetch("POST", API_URL, "/representative/logout", false, state.user.token.uuid , (result: any) => {
      if(result.status && result.status.success) {
        localStorage.removeItem("token");
        navigate("/wylogowanie", {replace: true});
      } else {
        localStorage.removeItem("token");
        navigate("/wylogowanie", {replace: true});
      }
  }, (error: any) => {
    dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
  })
});

interface RequestResetArgs {
  email: string
}

export const RequestReset = createAsyncThunk<any, RequestResetArgs>(
  "user/RequestReset",
  async (email, { dispatch, getState}) => {
    const state = getState() as RootState;
    return appFetch("POST", API_URL, "/representative/request-reset-password", 
      {
      representative: { emailAddress: email },
      reset: {
          return: {
              url: "https://"+window.location.hostname+"/nowe-haslo/?reset="
          }
      }}, state.user.token.uuid, (result: any) => {
      if(result.status && result.status.success) {

          return true;
      } else {
        dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))  
          return false;
      }
    }, (error: any) => {
      dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
    })
  }
);

export const RequestConfirmation = createAsyncThunk<any, RequestResetArgs>(
  "user/RequestConfirmation",
  async (email, { dispatch, getState}) => {
    const state = getState() as RootState;
    return appFetch("POST", API_URL, "/representative/request-confirmation", 
      {
      representative: { emailAddress: email },
      reset: {
          return: {
              url: "https://"+window.location.hostname+"/confirm/?reset="
          }
      }}, state.user.token.uuid, (result: any) => {
      if(result.status && result.status.success) {

          return true;
      } else {
        dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))  
          return false;
      }
    }, (error: any) => {
      dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
    })
  }
);

interface ResetArgs {
  pass: string,
  token: string,
  navigate: any
}

export const ResetPass = createAsyncThunk<any, ResetArgs>(
  "user/ResetPass",
  async ({pass, token, navigate}, { dispatch, rejectWithValue}) => {
    try {
      appFetch("POST", API_URL, "/representative/reset-password", {representative: {password: pass}}, token, (result: any) => {
        if(result.status && result.status.success) {
            navigate("/login", {replace: true})
            dispatch(showDrawer({type: 'info', data:{name: 'can_login', errorCode:""}}))
            return true;
        } else {
          dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))  
            return false;
        }
      }, (error: any) => {
        dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
      })
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

interface ConfirmArgs {
  data: {
    password: string,
    firstName: string,
    lastName: string
  }
  token: string,
  navigate: any
}

export const Confirm = createAsyncThunk<any, ConfirmArgs>(
  "user/Confirm",
  async ({data, token, navigate}, { dispatch, rejectWithValue}) => {
    try {
      appFetch("POST", API_URL, "/representative/confirm", {representative: data}, token, (result: any) => {
        if(result.status && result.status.success) {
            navigate("/login", {replace: true})
            dispatch(showDrawer({type: 'info', data:{name: 'can_login', errorCode:""}}))
            return true;
        } else {
          dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))  
            return false;
        }
      }, (error: any) => {
        dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
      })
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

interface PartialUpdateArgs {
  data: any
}

export const PartialUpdate = createAsyncThunk<any, PartialUpdateArgs>("user/PartialUpdate", 
  async ({data}, {dispatch, getState}) => {
    const state = getState() as RootState;
    return appFetch("POST", API_URL, "/representative/partial-update", data, state.user.token.uuid , (result: any) => {
      if(result.status && result.status.success) {
        dispatch(showDrawer({type: 'info', data: {name: 'data_saved' }}))
        return result.data;
      } else {
        dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
        return false;
      }
  }, (error: any) => {
    dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
  })
});

interface AvatarUpdateArgs {
  files: any
}

export const AvatarUpdate = createAsyncThunk<any, AvatarUpdateArgs>("user/AvatarUpdate", 
  async (files, {dispatch, getState}) => {
    const state = getState() as RootState;
    return fileFetch("POST", API_URL, "/representative/update-avatar", files, state.user.token.uuid , (result: any) => {
      if(result.status && result.status.success) {
        dispatch(UserDetails({navigate:false, isRedirect: false, token:state.user.token.uuid}))
        dispatch(showDrawer({type: 'info', data: {name: 'avatar_saved' }}))
        return result.data;
      } else {
        dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
        return false;
      }
  }, (error: any) => {
    dispatch(showDrawer({type: 'error', data: { name: 'generic', errorCode:'' } }))
  })
});