import React, { useEffect, useState } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import IMG from '../Login/img/login-image.jpg';
import IMG_MOBILE from '../Login/img/login-image-mobile.jpg';
import { Confirm } from '../../features/user/authService';

function NewPassPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const [tempToken, setTempToken] = useState('');
  const [tickValues, setTickValues] = useState([false,false,false,false,false,false]);

  const onSubmit = ({password, firstName, lastName, mobilePhone}: FieldValues) => {
    dispatch(Confirm({
      data: {
        firstName: firstName,
        lastName: lastName,
        password: password
      }, 
      token: tempToken, navigate: navigate}))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  useEffect(()=>{
    if (location.search.indexOf("reset") !== -1 ) {
      let token = location.search.split("=")[1];
      setTempToken(token)
    }
  },[])

  const handleChange = (e: any) => {
    const value = e.target.value;
    const tickValues = [];
    /[a-z]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    /[A-Z]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    /[0-9]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    /[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    value.length >= 8 ? tickValues.push(true) : tickValues.push(false);
    /\s/.test(value) ? tickValues.push(false) : tickValues.push(true);
    setTickValues(tickValues)
  }

  return (
    <PageLayout>
      <div className="page Login form-columns">
        <div className="image-column">
          <img className="form-image" src={IMG} />
        </div>
        <div className="form-column">
          <h1>Uzupełnij dane</h1>
          <img className="form-image-mobile" src={IMG_MOBILE} />
          <form autoComplete="false">
            <div className="input-item">
              <label htmlFor="field2">Imię</label>
              <input placeholder='Jan' role="presentation" autoComplete="false" className={ errors.firstName && 'error-input' } type="text" id="field2" {...register("firstName", {required: true, pattern:/^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/})} ></input>
              {errors.firstName && errors.firstName.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.firstName && errors.firstName.type === "required" && (
                <p className="error">Wpisz imię</p>
              )}
            </div>
            <div className="input-item">
              <label htmlFor="field3">Nazwisko</label>
              <input placeholder='Kowalski' role="presentation" autoComplete="false" className={ errors.lastName && 'error-input' } type="text" id="field3" {...register("lastName", {required: true, pattern:/^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/})} ></input>
              {errors.lastName && errors.lastName.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.lastName && errors.lastName.type === "required" && (
                <p className="error">Wpisz nazwisko</p>
              )}
            </div>
            {/*<div className="input-item phone-input">
              <label htmlFor="field1">Numer telefonu</label>
              <input placeholder='601602603' className={ errors.mobilePhone && 'error-input' } type="text" id="field1" autoComplete="phone" {...register("mobilePhone", {required: true, pattern: /^\d{9}$/})} ></input>
              {errors.mobilePhone && errors.mobilePhone.type === "pattern" && (
                <p className="error">Nieprawidłowy numer telefonu</p>
              )}
              {errors.mobilePhone && errors.mobilePhone.type === "required" && (
                <p className="error">Wpisz numer telefonu</p>
              )}
              </div>*/}
            <p className="text-center"><strong>Ustaw hasło: min. 8 znaków, 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny</strong></p>
            <div className="input-item">
              <label htmlFor="password">Hasło</label>
              <input className={ errors.password && 'error-input' } type="password" id="password" placeholder="Wpisz hasło" autoComplete="new-password" {...register("password", {required: true, pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]).{8,}$/, onChange: (e) => { handleChange(e)}})} ></input>
              {errors.password && errors.password.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.password && errors.password.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <div className="ticks">
              <div className={ tickValues[0] ? "tick active" : "tick" }>Mała litera</div>
              <div className={ tickValues[1] ? "tick active" : "tick" }>Wielka litera</div>
              <div className={ tickValues[2] ? "tick active" : "tick" }>Cyfra</div>
              <div className={ tickValues[3] ? "tick active" : "tick" }>Znak specjalny</div>
              <div className={ tickValues[4] ? "tick active" : "tick" }>Minimum 8 znaków</div>
              <div className={ tickValues[5] ? "tick active" : "tick" }>Brak spacji</div>
            </div>
            <Button onClick={ handleSubmit(onSubmit, onError) }>Zapisz i zaloguj się</Button>
            <div className="text-center margin-20">
              <Link to="/">Powrót do logowania</Link>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
}

export default NewPassPage
