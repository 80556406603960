import React from "react";
import Content from "../../components/PageLayout/Content/Content";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import Button from "../../components/Button/Button";
import "./Page404.scss";

function Page404() {
  return (
    <PageLayout>
      <div className="page Page404">
        <Content>
          <div className="wrapper">
            <div className="error-box">404</div>
            <h2 className="title">
              Zaczekaj chwilę.
              <br /> Nie zgubiliśmy się?
            </h2>
            {/*<p className="desc">
              Tu nic nie sprzedamy.
              <br /> Lepiej wróćmy do naszej strefy sprzedaży.
  </p>*/}
            <Button path="/dashboard">Wróć na stronę główną</Button>
          </div>
        </Content>
      </div>
    </PageLayout>
  );
}

export default Page404;
