import React from 'react';
import {
  useLocation,
  NavLink,
  Link
} from 'react-router-dom'
import { useWindowDimensions, useScrollPosition, useAppDispatch, useAppSelector} from '../../hooks';
import { ReactComponent as IconMenu } from './img/icon-menu.svg';
import { ReactComponent as Logo } from './img/logo-rep.svg';
import { ReactComponent as Bell } from './img/icon-bell.svg';
import ButtonBack from '../Button/ButtonBack/ButtonBack';
import './Header.scss';
import { openMenu } from '../../features/ui/uiSlice';
import { useNavigate } from "react-router-dom";
import { ReactComponent as ColorLine } from "./img/kreski.svg";
import { selectToken, selectUserData } from '../../features/user/userSlice';
import ProfilePic from '../ProfilePic/ProfilePic';

interface Props {
  castrated?: boolean
}

function Header({castrated}: Props) {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const {width, height} = useWindowDimensions();
  const navigate = useNavigate();
  const scrollPosition = useScrollPosition();
  const changeHeaderToFixed = scrollPosition > 28;
  const user = useAppSelector(selectUserData);

  return (
    <>
    <header className={changeHeaderToFixed ? "Header fixed" : "Header"}>
      { castrated ? 
        <>
          <ColorLine className="color-line" preserveAspectRatio="none" />
          <div className="header-inner">
            <div className="logo"><Logo/></div>
          </div>
        </>
        :
        <>
          <ColorLine className="color-line with-info" preserveAspectRatio="none" />
          <div className="header-inner with-info">
            <div className="nav-trigger" onClick={ ()=>{ dispatch(openMenu()) }}><IconMenu /></div>
            <div className="profile-container">
              <Bell style={{marginRight: "12px"}}/>
              <div className="name-container">
                <div className="name">{ user?.firstName }</div>
                <div className="title">BAT Rep</div>
              </div>
              <Link to="/profil">
                <ProfilePic />
              </Link>
            </div>
            { width < 1270 ?
                location.pathname != '/dashboard' ? 
                <ButtonBack/> : <div className="logo"><Logo/></div>
              : <div className="logo"><Link to="/dashboard"><Logo role="link"/></Link></div>
            }
          </div>
        </>
      }
  </header>
    
    </>
    
  );
}

export default Header
