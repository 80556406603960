import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, appFetch } from "../../fetch";
import type { RootState } from "../../store";

interface DateType {
  from: string,
  to: string
}

export const GetStatsDay = createAsyncThunk<any, DateType>(
  "stats/GetStatsDay", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }

    return appFetch("POST", API_URL, "/representative/stats/day", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

export const GetStatsMonth = createAsyncThunk<any, DateType>(
  "stats/GetStatsMonth", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }

    return appFetch("POST", API_URL, "/representative/stats/month", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

export const GetStatsYear = createAsyncThunk<any, DateType>(
  "stats/GetStatsYear", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }
    
    return appFetch("POST", API_URL, "/representative/stats/year", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

export const GetStatsSummary = createAsyncThunk<any, DateType>(
  "stats/GetStatsSummary", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }
    
    return appFetch("POST", API_URL, "/representative/stats/summary", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

interface DatePopType {
  from: string,
  to: string,
  popid: string | undefined
}

export const GetStatsDayPop = createAsyncThunk<any, DatePopType>(
  "stats/GetStatsDayPop", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }
    return appFetch("POST", API_URL, "/representative/stats/shop/day", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {

      }
  }, (error:any) => {

  })

});

export const GetStatsMonthPop = createAsyncThunk<any, DatePopType>(
  "stats/GetStatsMonthPop", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }

    return appFetch("POST", API_URL, "/representative/stats/shop/month", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {

      }
  }, (error:any) => {

  })

});

export const GetStatsYearPop = createAsyncThunk<any, DatePopType>(
  "stats/GetStatsYearPop", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }
    
    return appFetch("POST", API_URL, "/representative/stats/shop/year", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {

      }
  }, (error:any) => {

  })

});

