import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PageLayout from '../../components/PageLayout/PageLayout';
import { Register, RequestConfirmation, RequestReset } from '../../features/user/authService';
import { selectRegisterSuccess } from '../../features/user/userSlice';
import '../../theme/forms.scss';
import './Activation.scss';
import prefixes from '../../prefixes'
import IMG from '../Login/img/login-image.jpg';
import IMG_MOBILE from '../Login/img/login-image-mobile.jpg';
import { useAppDispatch, useAppSelector } from '../../hooks';

interface registerProps {
  firstName: string;
  lastName: string;
  salesPoint: string;
}

function ActivationPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registerSuccess = useAppSelector(selectRegisterSuccess);
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const [formStep, setFormStep] = useState(0);
  const [showInfo, setShowInfo] = useState(false);


  const onSubmit = ({firstName, lastName, salesPointPrefix, salesPointNumber}: FieldValues) => {
    const  formPart = getValues()
    dispatch(RequestConfirmation(formPart.emailAddress))
  }

  const onError = (errors: FieldErrors) => {
    console.log(errors)
  }


  const validatePhone = (value: string) => {
    if (value.length >=9 && value.length <= 12) {
      for (let i = 0; i < prefixes.length; i++) {
        if ( prefixes[i] === value.substring(0, prefixes[i].length)) {
          return true
        }
      }
    } 
    return false;
  }

  useEffect(()=>{
    setValue('salesPointPrefix', 'TT')
  }, [])

  return (
    <PageLayout>
      <div className="page Register form-columns">
        <div className="image-column">
          <img className="form-image" src={IMG} />
        </div>
        <div className="form-column">
          <ul className="register-page-nav">
            <li className={formStep >= 0 ? "active" : ""}></li>
            <li className={formStep >= 1 ? "active" : ""}></li>
          </ul>
          
          <form>
            <div className={formStep === 0 && !registerSuccess ? "form-step active": "form-step"}>
              <h1>Aktywacja konta</h1>
              <img className="form-image-mobile" src={IMG_MOBILE} />
              <p className="text-center">Podaj swój adres e-mail i zaakceptuj zgody, abyśmy mogli Cię zweryfikować.</p>
              <div className="input-item">
                <label htmlFor="emailAddress">E-mail</label>
                <input placeholder='jan@example.pl' className={ errors.emailAddress && 'error-input' } type="text" id="emailAddress" {...register("emailAddress", {required: true, pattern:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></input>
                {errors.emailAddress && errors.emailAddress.type === "pattern" && (
                  <p className="error">Nieprawidłowy adres e-mail</p>
                )}
                {errors.emailAddress && errors.emailAddress.type === "required" && (
                  <p className="error">Wpisz adres e-mail</p>
                )}
              </div>
              {/*<div className="checkbox-item">
                <input type="checkbox" id="ageVerified" {...register("ageVerified", { required: true })}  />
                <label htmlFor="ageVerified">Potwierdzam, że jestem osobą pełnoletnią i jestem uprawniony/a do wzięcia udziału w Programie zgodnie z postanowieniami Regulaminu*</label>
                {errors.ageVerified && errors.ageVerified.type === "required" && (
                  <p className="error">Zgoda wymagana</p>
                )}
              </div>
              <div className="checkbox-item">
                <input type="checkbox" id="consentAgreement" {...register("consentAgreement", { required: true })}  />
                <label htmlFor="consentAgreement">Akceptuję warunki Programu określone w <a href="/pdf/regulamin_glowny_bat_partners2022.pdf" target="_blank">Regulaminie Programu</a>*</label>
                {errors.consentAgreement && errors.consentAgreement.type === "required" && (
                  <p className="error">Zgoda wymagana</p>
                )}
                </div>*/}
              <div className="checkbox-item">
                <input type="checkbox" id="marketingConsent" {...register("marketingConsent")}  />
                <label htmlFor="marketingConsent">Wyrażam zgodę na przetwarzanie moich danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie (BAT) w celu przesyłania mi informacji na temat produktów i usług oferowanych przez BAT.</label>
              </div>
              <p className="text-11" style={{ margin: '.5em 0 1em'}}>Jako formę kontaktu na potrzeby przesyłania informacji na temat produktów i usług BAT wybieram:</p>
              <div className="checkbox-item">
                <input type="checkbox" id="emailContact" {...register("emailContact")}  />
                <label htmlFor="emailContact">wiadomości przesyłane drogą elektroniczną (e-mail, SMS, serwisy internetowe, okienka pop up)</label>
              </div>
              <div className="checkbox-item">
                <input type="checkbox" id="phoneContact" {...register("phoneContact")}  />
                <label htmlFor="phoneContact">połączenia głosowe (rozmowy telefoniczne, komunikaty IVR)</label>
              </div>
              {/*<div className="disclaimer">
              * Zgody wymagane do rejestracji.
              </div>*/}
              <Button onClick={ handleSubmit(onSubmit, onError) }>Aktywuj konto</Button>
              <div className="text-center margin-20">
                <Link to="/login">Powrót do logowania</Link>
              </div>
            </div>

            <div className={registerSuccess ? "form-step active": "form-step"}>
              <h1>Aktywacja konta</h1>
              <h2 className="h1 color-secondary" style={{ margin: '1.5em 0'}}>Brawo!</h2>
              <p className="text-center">Na Twój adres e-mail wysłaliśmy link potwierdzający. <strong>Kliknij w niego aby ustawić hasło i dokończyć rejestrację.</strong></p>
              <p className="text-center">Jeśli wiadomość nie dotrze <strong>w ciągu 5 minut</strong> sprawdź skrzynkę SPAM.</p>
              <div className="text-center margin-20">
                <Link to="/">Powrót do logowania</Link>
              </div>
            </div>
          </form>
          <InfoBottom />
        </div>
      </div>

    </PageLayout>
  );
}

export default ActivationPage

function InfoBottom() {
  return (
    <p className="info-bottom">Administratorem danych wskazanych w trakcie rejestracji jest British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie przy ul. Krakowiaków 48. Skontaktuj się z nami telefonicznie pod numerem 800 610 610 lub mailowo na adres <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a> Dane będą przez nas wykorzystywane dla celów związanych z Twoim uczestnictwem w programie BAT Partners, w tym utworzenia Twojego konta uczestnika. W związku z podaniem danych osobowych przysługuje Ci prawo dostępu do treści Twoich danych, prawo ich poprawiania, usunięcia lub przenoszenia, a także prawo do żądania ograniczenia ich przetwarzania. Przysługuje Ci także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Więcej informacji znajdziesz w informacji o przetwarzaniu danych na końcu <a href="/pdf/regulamin_glowny_bat_partners2022.pdf" target="_blank">Regulaminu</a>.<br/><br/>
    W razie pytań lub problemów technicznych napisz do nas na adres <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a></p>
  )
}
