import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Statistics.scss";
import StatisticsBarChart from "./StatisticsBarChart";
import DATA from "../../data/owner-results-date-sort.json";
import { ChartType, DateType } from "./StatisticsBarChart";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import dayjs from "dayjs";
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as ArrowNext } from "./arrow-next.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { GetStatsDay, GetStatsMonth, GetStatsYear } from "../../features/stats/statsService";
import { selectShops, selectStatsData } from "../../features/stats/statsSlice";
import 'dayjs/locale/pl';
import DashboardLineChart from "./DashboardLineChart";
import { selectTerritories, selectUserData } from "../../features/user/userSlice";
import { Link, NavLink } from "react-router-dom";

interface DateSelectProps {
  dateCounter: number,
  dateType: DateType,
  setDateCounter: any
}

function DateSelect({dateCounter, dateType, setDateCounter}:DateSelectProps) {

  const [select, setSelect] = useState(<></>)

  useEffect(()=>{
    switch(dateType) {
      case 'week':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'week').startOf('week').format('DD.MM')+' - '+dayjs().add(dateCounter, 'week').endOf('week').format('DD.MM') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'month':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().locale('pl').add(dateCounter, 'month').format('MMMM YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'year':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'year').format('YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'full':
        setSelect(
          <div className="DateSelect">
            <div className="date-range">2021 - {dayjs().format('YYYY')}</div>
          </div>
        )
      break;
    }
  },[dateCounter, dateType])
  return (
    <>
      { select }
    </>
  )
}

function Statistics() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData);
  const [showEmployees, setShowEmployees] = useState(false)
  const [showInactiveEmployees, setShowInactiveEmployees] = useState(false)
  const [chartType, setChartType] = useState<ChartType>("device")
  const [dateType, setDateType] = useState<DateType>("week");
  const chartData = useAppSelector(selectStatsData)
  const devicesData = {
    device: chartData.reduce((sum:number, item:any) => sum + item?.total?.device, 0),
    referral: chartData.reduce((sum:number, item:any) => sum + item?.total?.referral, 0),
    balance: chartData.reduce((sum:number, item:any) => sum + item?.total?.balance, 0),
  }
  const employees = useAppSelector(selectShops);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const territories = useAppSelector(selectTerritories);

  const colors = ['#15AFE6', '#FF0321', '#FF8D03', '#009B3E', '#8000FF'];
  const text = {
    device: {
      name: "urządzeń",
      title: "Całkowita ilość sprzedaży"
    },
    referral: {
      name: "poleceń",
      title: "Całkowita ilość poleceń"
    },
    balance: {
      name: "PLN",
      title: "Całkowita zebrana kwota"
    },
  }

  const [dateCounter, setDateCounter] = useState(0);

  useEffect(()=>{
    if (dateType === 'week') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'week').startOf('week').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'week').endOf('week').format('YYYY-MM-DD')}))
    } else if (dateType === 'month') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'month').startOf('month').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'month').endOf('month').format('YYYY-MM-DD')}))
    } else if (dateType === 'year') {
      dispatch(GetStatsMonth({from: dayjs().add(dateCounter, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'year').endOf('year').format('YYYY-MM-DD')}))
    } else if (dateType === 'full') {
      dispatch(GetStatsYear({from: dayjs().add(-1, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().endOf('year').format('YYYY-MM-DD')}))
    }
  }, [dateCounter, dateType])

  useEffect(()=>{
    setSelectedEmployeesData(employees.filter((item:any, key:number)=> selectedEmployees.indexOf(item.popid) > -1))
  }, [selectedEmployees])

  const toggleEmployee = (id:string) => {
    if (selectedEmployees.indexOf(id) > -1) {
      setSelectedEmployees(selectedEmployees.filter((item:any)=> item !== id))
    } else {
      if (selectedEmployees.length < 5)
        setSelectedEmployees([...selectedEmployees, id])
    }
  }

  return (
    <PageLayout>
      <div className="page Statistics">
        <div className="dash-head">
          <h1>Statystyki</h1>
        </div>
        <div className="dropshadow-box chart-box">
          <div className="chart-head">
            <div className="chart-options">
              <div className="data-options">
                <DropDownTabs
                  handleChangeTab={(val: any) => {setChartType(val)}}
                  activeTab={chartType}
                  tabList={[{name: "Sprzedane urządzenia", value: "device"}, {name: "Polecenia", value: "referral"}]}
                  color={"#fff"}
                />
              </div>
              <div className="date-options">
                <DropDownTabs
                  handleChangeTab={(val: any) => {setDateType(val); setDateCounter(0)}}
                  activeTab={dateType}
                  tabList={[{name: "Tydzień", value: "week"}, {name: "Miesiąc", value: "month"}, {name: "Rok", value: "year"}, {name: "Cały okres", value: "full"}]}
                  color={"#fff"}
                />
                <DateSelect dateCounter={dateCounter} dateType={dateType} setDateCounter={setDateCounter} />
              </div>
            </div>
            <p className="sold-devices">Całkowita sprzedaż w tym okresie na twoim terytorium - <strong>{ devicesData[chartType] } <span>{text[chartType].name}</span></strong></p>
          </div>
          <DashboardLineChart type={chartType} dateType={dateType} employeesData={selectedEmployeesData} employees={showEmployees} monthDays={parseInt(dayjs().add(dateCounter, 'month').endOf('month').format('DD'))} />
          <div className="chart-bottom">
            <div className="chart-bottom-options">
              <div className="checkbox-item chart-checkbox">
                <input type="checkbox" id="employees" checked={showEmployees} onChange={()=>setShowEmployees((val)=>!val)} />
                <label htmlFor="employees">Zobacz podział na punkty sprzedaży</label>
              </div>
              { showEmployees &&
                <div className="checkbox-item chart-checkbox">
                  <input type="checkbox" id="iemployees" checked={showInactiveEmployees} onChange={()=> setShowInactiveEmployees((val)=>!val)} />
                  <label htmlFor="iemployees">Dodaj nieaktywne punkty sprzedaży</label>
                </div>
              }
            </div>
            { showEmployees &&
              <>
              <div className="chart-employees-top">
                <div>
                  <h3>Wybierz punkty sprzedaży do porównania*</h3>
                  <p>*Jednorazowo możesz wybrać maksymalnie 5 punktów sprzedaży</p>
                </div>
                <div className="search-input">
                  <div className="input-item"><input type="text" placeholder="Wpisz adres lub kod punktu..." onChange={(e)=>setSearch(e.target.value)} /></div>
                </div>
              </div>
              <div className="chart-employees">

                { employees.filter((shop:any,key:number) => {
                  if (!showInactiveEmployees && shop.inactive) {
                    return false;
                  }

                  if (search) {
                    const territory = territories.filter((item:any)=>item.popid === shop.popid)[0]
                    const address = territory?.street+', '+territory?.postalCode+', '+territory?.cityName
                    if (  shop.popid.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                      return true;
                    } else if ( territory && address.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }).map((item:any,key:number) => {
                  const territory = territories.filter((t:any)=>t.popid === item.popid)[0]
                  const address = territory?.street+', '+territory?.postalCode+', '+territory?.cityName
                  const popIndex = selectedEmployees.indexOf(item.popid)
                  const isSelected = popIndex > -1 ? true : false
                  return (
                    <div className={ !isSelected && selectedEmployees.length === 5 ? "sumup-box sumup-box-employee inactive" : 'sumup-box sumup-box-employee'} key={'employee-'+key} >
                      <div className="sumup-top" onClick={()=>toggleEmployee(item.popid)}>
                        <div className="name"><span className={ isSelected ? "employee-checkmark active" : "employee-checkmark"}></span>{ item.popid }</div>
                        <div className="sold">
                          <div className="chart-line" style={{backgroundColor: colors[popIndex]}}><div className="chart-line-point" style={{backgroundColor: colors[popIndex]}}></div></div>
                          { item[chartType]+' urządzeń' }
                        </div>
                      </div>
                      <div className="sumup-address">{ address }</div>
                      <Link to={"/statystyki/"+item.popid.toLowerCase()}>{"Zobacz szczegóły punktu"}</Link>
                    </div>
                  )
                })}
               </div>
            </>
            }
          </div>
        </div>
        <p className="chart-notice">{`Aktualizacje co 24 godziny. Ostatnia aktualizacja z dnia ${dayjs().add(-1, 'day').format('DD.MM.YYYY')} godz. 23:59`}</p>
      </div>
    </PageLayout>
  );
}

export default Statistics;
