// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Profile.scss";
import { useWindowDimensions, useAppDispatch, useAppSelector } from "../../hooks";
import Button from '../../components/Button/Button';
import { selectUserData } from "../../features/user/userSlice";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import NoProfilPic from "./img/no-profil-pic.svg";
import { AvatarUpdate, PartialUpdate } from '../../features/user/authService';
import ProfilePic from "../../components/ProfilePic/ProfilePic";

function Profile() {
  const dispatch = useAppDispatch();
  const {width, height} = useWindowDimensions();
  const [editProfile, setEditProfile] = useState(false)
  const user = useAppSelector(selectUserData)
  const [textAreaCounter, setTextAreaCounter] = useState(user?.bio?.length || 0)
  const { register, setValue, handleSubmit, getValues, formState: { errors} } = useForm();
  const limitSizeInBites = 5000000;
  const [limit, setLimit] = useState(false);

  useEffect(() => {
    setTextAreaCounter(user?.bio?.length || 0)
  }, [])

  const updateFile = (file) => {
    if(file.size >= limitSizeInBites) {
      setLimit(true)
    } else {
      setLimit(false);
      const fd = new FormData();
      fd.append('files[]', file);
      console.log(fd.entries())
      dispatch(AvatarUpdate(fd))
    }
  }

  const onSubmit = (data: FieldValues) => {
    console.log(getValues())
    dispatch(PartialUpdate({ data: { representative: getValues()}}))
  }

  const onError = () => {
    console.log(getValues())
  }

  const validatePhone = (value: string) => {
    if (value.length >=9 && value.length <= 12) {
      return true
    } 
    return false;
  }
    

  return (
    <PageLayout>
      <div className="page Profile">
        <div className={!editProfile ? "dropshadow-box chart-box profile-body" : "edit-box chart-box profile-body"}>
          {!editProfile ?
          <>
            <div className="img-container">
              <ProfilePic />
            </div>
            <div className="text-container">
              <h2>{ user?.firstName+' '+user?.lastName}</h2>
              <div className="text-data">
                <p><strong>Telefon kontaktowy: </strong><br/>{ user?.mobilePhone }</p>
                <p><strong>Numer terytorium: </strong><br/>{ user?.territory }</p>
                <p><strong>O mnie: </strong><br/>{user?.bio}</p>
              </div>
            <p className="edit-btn" onClick={() => setEditProfile(true)}>Edytuj Profil</p>
            </div>
          </>
          :
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="img-container">
              <label className="change-pic" htmlFor="file">
                <ProfilePic />
                <div>Zmień zdjęcie profilowe</div>
              </label>
              <span>Dozwolone rozszerzenia: <strong>.jpg</strong> <br />
              Maksymalny rozmiar pliku: <strong>5 MB</strong></span>
              <input className="change-pic" id="file" type="file" accept="image/png, image/jpeg" onChange={(e: any)=>{ updateFile(e.target.files[0]) }} style={{display: "none"}}/>
              {limit ? <p className="limit-warning">Maksymalna waga zdjęcia to: 7 MB</p> : null}
            </div>
            <div className="edit-container">
              <div className="data-edit">
                <div>
                  <label htmlFor="firstName">Imię</label>
                  <input placeholder='Jan' className={ errors.firstName && 'error-input' } type="text" id="firstName" {...register("firstName", {required: true, pattern:/^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/})} defaultValue={user?.firstName}></input>
                  {errors.firstName && errors.firstName.type === "pattern" && (
                    <p className="error">Nieprawidłowa wartość</p>
                  )}
                  {errors.firstName && errors.firstName.type === "required" && (
                    <p className="error">Wpisz imię</p>
                  )}
                </div>
                <div>
                <label htmlFor="lastName">Nazwisko</label>
                <input placeholder='Kowalski' className={ errors.lastName && 'error-input' } type="text" id="lastName" {...register("lastName", {required: true, pattern:/^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/})} defaultValue={user?.lastName}></input>
                {errors.lastName && errors.lastName.type === "pattern" && (
                  <p className="error">Nieprawidłowa wartość</p>
                )}
                {errors.lastName && errors.lastName.type === "required" && (
                  <p className="error">Wpisz nazwisko</p>
                )}
                </div>
                <div>
                  <label htmlFor="mobilePhone">Numer telefonu</label>
                  <input  className={ errors.mobilePhone && 'error-input' } type="text" id="mobilePhone" {...register("mobilePhone", {required: true, validate: value => validatePhone(value)})} defaultValue={user?.mobilePhone}></input>
                  {errors.mobilePhone && errors.mobilePhone.type === "validate" && (
                    <p className="error">Nieprawidłowy numer telefonu</p>
                  )}
                  {errors.mobilePhone && errors.mobilePhone.type === "required" && (
                    <p className="error">Wpisz numer telefonu</p>
                  )}
                </div>
                <div>
                  <label htmlFor="territory">Numer terytorium</label>
                  <input className={ errors.territory && 'error-input' } type="text" id="territory" {...register("territory", {required: true})} defaultValue={user?.territory}></input>
                  {errors.territory && errors.territory.type === "required" && (
                    <p className="error">Wpisz numer terytorium</p>
                  )}
                </div>
              </div>
              <div className="about-me-container">
                <label>O mnie</label>
                <textarea {...register("bio")} className="about-me" onChange={e => setTextAreaCounter(e.target.value.length)} maxLength={150} defaultValue={user?.bio}></textarea >
                <span className="coutner">{textAreaCounter}/150 znaków</span>
              </div>
              {/* <button onClick={() => setEditProfile(false)}>Zapisz zmiany</button> */}
              {/* <input type="submit" /> */}
              <Button onClick={ handleSubmit(onSubmit, onError) }>Zapisz zmiany</Button>
            </div>
            </form>
          }
        </div>
      </div>
    </PageLayout>
  );
}

export default Profile;