import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Dashboard.scss";
import { ChartType, DateType } from "./DashboardBarChart";
import dayjs from "dayjs";
import { ReactComponent as Arrow } from "./arrow.svg";
import { useWindowDimensions, useAppDispatch, useAppSelector } from "../../hooks";
import { GetStatsDay, GetStatsMonth, GetStatsSummary, GetStatsYear } from "../../features/stats/statsService";
import { selectStatsData, selectSummary } from "../../features/stats/statsSlice";
import 'dayjs/locale/pl';
import { Link } from "react-router-dom";
import { selectUserData } from "../../features/user/userSlice";

function Dashboard() {
  const dispatch = useAppDispatch();
  const {width, height} = useWindowDimensions();
  const XGA = 1020;
  const [dateType, setDateType] = useState<DateType>("week");
  const chartData = useAppSelector(selectStatsData);
  const user = useAppSelector(selectUserData);
  const summary = useAppSelector(selectSummary);

  const colors = ['#0EB5E0', '#7DE1FB', '#c3ecf7', '#ecf9fd', '#008cbc', '#66bad7'];
  const text = {
    device: {
      name: "urządzeń",
      title: "Całkowita ilość sprzedaży"
    },
    referral: {
      name: "poleceń",
      title: "Całkowita ilość poleceń"
    },
    balance: {
      name: "PLN",
      title: "Całkowita zebrana kwota"
    },
  }

  const [dateCounter, setDateCounter] = useState(0);

  useEffect(()=>{
    if (dateType === 'week') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'week').startOf('week').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'week').endOf('week').format('YYYY-MM-DD')}))
    } else if (dateType === 'month') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'month').startOf('month').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'month').endOf('month').format('YYYY-MM-DD')}))
    } else if (dateType === 'year') {
      dispatch(GetStatsMonth({from: dayjs().add(dateCounter, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'year').endOf('year').format('YYYY-MM-DD')}))
    } else if (dateType === 'full') {
      dispatch(GetStatsYear({from: dayjs().add(-1, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().endOf('year').format('YYYY-MM-DD')}))
    }
    console.log(user)
  }, [dateCounter, dateType])

  useEffect(()=>{
    dispatch(GetStatsSummary({from: dayjs().startOf("month").format('YYYY-DD-MM'), to: dayjs().endOf("month").format('YYYY-DD-MM')}))
  },[])

  return (
    <PageLayout>
      <div className="page Dashboard">
        <div className={width >= XGA ? "dash-head dropshadow-box" : "dash-head"}>
          <div className="welcome">
            <p>Witaj</p>
            <p className="name">{ user?.firstName }</p>
          </div>
          <div className="recommend-code">
            <p className="header">Kod polecający</p>
            <p>Przy rejestracji nowego BAT Partnera użyj kodu polecającego.</p>
            <button>{ user?.referralCode }</button>
          </div>
        </div>
        <div className="dropshadow-box chart-box dash-body">
          <div className="header-container">
            <p className="header">Podsumowanie <span>bieżącego miesiąca</span></p>
            {width >= XGA ? <Link className="link-statistics" to="/statystyki">Zobacz pełne statystyki <Arrow /></Link> : null}
          </div>
          <div className="box-container">
            <div className="box">
              <p><strong>Aktywne punkty sprzedaży</strong></p>
              <p><strong className="numbers">{ summary?.shops?.active } <span className="grey-number">/ { summary?.shops?.total }</span></strong> { summary?.shops?.new > 0 && <> w tym <strong>{summary?.shops?.new} nowe</strong></>}</p>
            </div>
            <div className="box">
              <p><strong>Aktywni sprzedawcy</strong></p>
              <p><strong className="numbers">{ summary?.clerks?.active } <span className="grey-number">/ { summary?.clerks?.total }</span></strong> { summary?.clerks?.new > 0 && <>w tym <strong>{summary?.clerks?.new} nowych</strong></>}</p>
            </div>
            <div className="box">
              <p><strong>Sprzedane urządzenia</strong></p>
              <p><strong className="numbers">{ summary?.devices?.new }</strong></p>
            </div>
            <div className="box">
              <p><strong>Poleconych konsumentów</strong></p>
              <p><strong className="numbers">{ summary?.referrals?.new }</strong></p>
            </div>
          </div>
          {width < XGA ? <Link className="link-statistics" to="/statystyki">Zobacz pełne statystyki <Arrow /></Link> : null}
        </div>
      </div>
    </PageLayout>
  );
}

export default Dashboard;
