interface errorObject {
  title: string
  description?: string,
  icon: 'exclamation' | 'locked',
  buttonText: string
}

export const errors: {[error: string]: any} = {
  "generic": shortError("Wystąpił nieoczekiwany błąd. Prosimy spróbuj ponownie za chwilę."),
  "error_unauthorized": shortError("Nie zalogowano, lub sesja wygasła."),
  "error_token_expired": shortError("Sesja wygasła"),
  "error_user_not_found": shortError("Użytkownik nie istnieje lub hasło jest nieprawidłowe"),
  "error_user_wrong_password": shortError("Błędne hasło"),
  "error_user_empty_password": shortError("error_user_empty_password"),
  "error_awaiting_otp": shortError("SMS został już wysłany. Prosimy poczekaj chwilę."),
  "error_wrong_otp": shortError("Błędny kod sms"),
  "error_expired_otp": shortError("Kod sms wygasł"),
  "error_daily_otp_limit_reached": shortError("Wykorzystałeś limit zmian. Spróbuj ponownie jutro."),
  "error_empty_token_list": shortError("error_empty_token_list"),
  "error_not_confirmed": shortError("error_not_confirmed"),
  "error_email_address_taken": shortError("Adres email zajęty"),
  "error_mobile_phone_taken": shortError("Numer telefonu zajęty"),
  "error_username_taken": shortError("Nazwa użytkownika zajęta"),
  "error_role_not_found": shortError("error_role_not_found"),
  "error_http_exception": shortError("Wystąpił błąd"),
  "error_device_not_found": shortError("Nieprawidłowy numer urządzenia"),
  "error_too_low_balance_value": shortError("Dokonaj pierwszej sprzedaży i odbierz swoją nagrodę. Wypłata środków, zgodnie z regulaminem możesz dokonać po sprzedaży pierwszego urządzenia."),
  "error_amount_of_registered_devices_reached": shortError("Wykorzystałeś limit rejestracji. Spróbuj ponownie jutro."),
  "error_billon_exception": shortError("Przepraszamy, wystąpił błąd komunikacji z systemem wypłat, prosimy spróbuj ponownie później."),
  "error_billon_over_budget": shortError("Przepraszamy, wystąpił błąd komunikacji z systemem wypłat, prosimy spróbuj ponownie później."),
  "error_upload_exception": shortError("Wystąpił nieoczekiwany błąd. Prosimy spróbuj ponownie za chwilę."),
  "error_wrong_serial_number_limit_exceeded": {
    title: 'Twoje konto zostało zablokowane',
    description: '<strong class="color-red">Ze względu na pięciokrotne podanie błędnego numeru seryjnego urządzenia, możliwość rejestracji została zablokowana.</strong> <br/><br/>W celu odblokowania konta prosimy skontaktuj się z nami na <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a> przekazując widoczny powyżej kod błędu oraz numer seryjny urządzenia i zdjęcie dowodu jego sprzedaży.',
    icon: 'locked',
    buttonText: 'Rozumiem'
  },
  "error_wrong_serial_number_near_to_limit": {
    title: 'Niepoprawny numer seryjny urządzenia',
    description: '<strong class="color-red">Ponownie wprowadzono błędny numer seryjny urządzenia.</strong><br/><br/>Jeżeli masz pewność, że numer jest poprawny - prosimy wyślij zgłoszenie na <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>, wraz ze zdjęciem urządzenia z widocznym wyraźnym numerem seryjnym i dowodem sprzedaży. Pamiętaj, że po piątym wprowadzeniu błędnego numeru seryjnego, na konto zostanie nałożona blokada rejestracji.',
    icon: 'exclamation',
    buttonText: 'Rozumiem'
  },
  "error_zero_results": {
    title: 'Brak wyników',
    description: '<strong class="color-red">Nie udało się wykryć prawidłowego numeru. Spróbuj ponownie.</strong>',
    icon: 'exclamation',
    buttonText: 'Ok'  
  },
  "error_user_blacklisted": {
    title: 'Konto zablokowane',
    description: '<strong class="color-red">Twoje konto zostało tymczasowo zablokowane, w celu odblokowania konta prosimy skontaktuj się z nami </strong> <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>',
    icon: 'exclamation',
    buttonText: 'Ok'
  }
}

export const infos: {[info: string]: any} = {
  "otp_sent": shortError("Kod został wysłany"),
  "can_login": shortError("Ustawiono nowe hasło. Możesz się zalogować."),
  "data_saved": shortError("Dane zostały zapisane"),
  "avatar_saved": shortError("Zdjęcie zostało zapisane"),
  "payout": shortError("Twoja wypłata jest procesowana")
}

function shortError(msg: string) {
  return (
    {
      title: msg,
      buttonText: 'Zamknij',
      icon: 'exclamation'
    }
  )
}