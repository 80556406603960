import React, { Component, ReactComponentElement } from 'react'
import Modal from 'react-modal'
import { ReactComponent as IconExclamation } from './img/icon-exclamation.svg';
import { ReactComponent as IconLocked } from './img/icon-locked.svg';
import { ReactComponent as IconPrize } from './img/icon-prize.svg';
import { ReactComponent as IconHourglass } from './img/icon-hourglass.svg';
import { ReactComponent as IconChecked } from './img/icon-checked.svg';
import Button from '../Button/Button';
import { errors, infos } from './messages';
import './Drawer.scss';
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { hideDrawer, selectUI } from '../../features/ui/uiSlice';
import { selectUserData } from '../../features/user/userSlice';

Modal.setAppElement('#root')

export default function Drawer() {
  const dispatch = useAppDispatch()
  const ui = useAppSelector(selectUI)
  const user = useAppSelector(selectUserData)
  const navigate = useNavigate();

  const icons: { [icon: string]: any} = {
    'exclamation': <IconExclamation />,
    'locked': <IconLocked />,
    'hourglass': <IconHourglass />
  }

  const handleSwipe = (e: any) => {
    if (e.direction === 16) {
      dispatch(hideDrawer())
    }
  }

  const drawerContent = () => {
    switch(ui.drawerType) {
      case 'error':
        let errorName: any = ui.drawerData.name;
        const errorCode: string = ui.drawerData.errorCode;
         if (!errors[errorName]) errorName = "generic"
        return (
          <div className="drawer-inner drawer-error">
            { errors[errorName].icon && icons[errors[errorName].icon] }
            <h2>{ errors[errorName].title }</h2>
            <p className="color-red"><strong>Kod błędu: { errorCode }</strong></p>
            { errors[errorName].description && <p dangerouslySetInnerHTML={{ __html: errors[errorName].description }}></p> }
            <Button onClick={ () => { dispatch(hideDrawer()) }} >{ errors[errorName].buttonText }</Button>
          </div>
        )
      case 'info':
        const infoName: any = ui.drawerData.name;
        return (
          <div className="drawer-inner drawer-info">
            <h2>{ infos[infoName].title }</h2>
            { infos[infoName].description && <p>{ infos[infoName].description }</p> }
            <Button onClick={ () => { dispatch(hideDrawer()) }} >{ infos[infoName].buttonText }</Button>
          </div>
        )
      default:
        return <div></div>
    }
  }

  return (
    <Modal
      isOpen={ ui.showDrawer } 
      contentLabel="Modal" 
      className={{
        base: 'drawer',
        afterOpen: 'drawer_after-open',
        beforeClose: 'drawer_before-close'
      }}
      overlayClassName={{
        base: 'overlay',
        afterOpen: 'overlay_after-open',
        beforeClose: 'overlay_before-close'
      }} 
      onRequestClose={ () => { dispatch(hideDrawer()) } } 
      closeTimeoutMS={ 500 }
    >
      <div className="swipe-close">
        { drawerContent() }
        <button className="btn-close" onClick={ () => { dispatch(hideDrawer()) } }><div className="icon"></div></button>
      </div>
    </Modal>
  )
}