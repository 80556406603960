import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { GetRankingRegion, GetRankingGlobal } from './rankingService';
import { RootState } from '../../store';

export interface RankingState {
  region: any,
  global: any,
  userReg: any,
  userGlob: any,
  isLoading: boolean,
}

const initialState: RankingState = {
  region: [],
  global: [],
  userReg: {
    firstName: '',
    counter: 0,
    position: 0
  },
  userGlob: {
    firstName: '',
    counter: 0,
    position: 0
  },
  isLoading: false
};

export const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(
      GetRankingRegion.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.region = action.payload.data.ranking;
        state.userReg = action.payload.data.user;
      }
    )
    .addCase(
      GetRankingGlobal.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.global = action.payload.data.ranking;
        state.userGlob = action.payload.data.user;
      }
    )
    .addMatcher(
      isAnyOf(GetRankingRegion.pending, GetRankingGlobal.pending),
      (state) => {
        state.isLoading = true;
      }
    )
  },
});

// Exporting pieces of state to be used in components with useSelector Hook
export const selectRankingRegion = (state: RootState) => state.ranking.region;
export const selectRankingGlobal = (state: RootState) => state.ranking.global;
export const selectUserRankingRegion = (state: RootState) => state.ranking.userReg;
export const selectUserRankingGlobal = (state: RootState) => state.ranking.userGlob;

// Reducer export
export default rankingSlice.reducer