import { selectToken, selectUserData } from "../../features/user/userSlice";
import { API_URL } from "../../fetch";
import { useAppSelector } from "../../hooks";
import NoProfilPic from "./no-profil-pic.svg";

const ProfilePic = () => {
  const user = useAppSelector(selectUserData);
  const token = useAppSelector(selectToken);

  return (
    <img className="profile-pic" src={user?.avatar?.uuid ? `${API_URL}/file/serve/${user?.avatar?.uuid}?token=${token?.uuid}` : NoProfilPic} alt="Zdjęcie profilowe" title="Twój profil" />
  )
};

export default ProfilePic;
