import "./Footer.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Mail } from "./img/mail.svg";
import { ReactComponent as Phone } from "./img/phone.svg";
import { ReactComponent as Time } from "./img/time.svg";
import { ReactComponent as Chevron } from "./img/chevron.svg";
import { ReactComponent as Logo } from "../header/img/logo-rep.svg";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
    { location.pathname === "/dashboard" || location.pathname === "/ranking" || location.pathname.indexOf('/statystyki') >= 0|| location.pathname === "/profil" || location.pathname === "/punkty-sprzedazy" || location.pathname.indexOf('/news') >= 0 ? 
      <div className="Footer">
        <div className="footer-inner">

          <div className="go-top" onClick={()=>window.scrollTo(0, 0)}>
            <Chevron />
          </div>
          <div className="footer-columns">
            <div className="footer-column links-column">
              <Link to="/">Regulamin</Link>
              <Link to="/">Preferencje cookies</Link>
              <Link to="/">Polityka cookies</Link>
              <Link to="/">Polityka prywatności</Link>
            </div>
            <div className="footer-column contact-column">
              <h3>Kontakt</h3>
              <div className="contact-row">
                <Mail/>
                <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>
              </div>
              <div className="contact-row">
                <Phone/>
                <a href="tel:+48800610610">800 610 610</a>
              </div>
              <div className="contact-row">
                <Time/>
                <p>Bezpłatna infolinia jest czynnna od poniedziałku do niedzieli w godzinach 8:00 - 20:00</p>
              </div>
            </div>
            <div className="footer-column rodo-column">
              <strong>Zgłoszenia dotyczące danych osobowych:</strong>
              <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a>
              <p className="copy">© 2022 British American Tobacco. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    : <div></div>
    }
    </>
  );
}

export default Footer;
