import React, { useEffect, useState } from "react";
import Routes from "./app/Routes";
import "./App.scss";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "./app/hooks";
import { UserDetails } from "./app/features/user/authService"; 
import dayjs from "dayjs";
import Loading from "./app/components/Loading/LoadingScreen/Loading";
import { selectUI } from "./app/features/ui/uiSlice";

function App() {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("token");
  const ui = useAppSelector(selectUI);
  const {height} = useWindowDimensions();

  useEffect(()=>{
    const tkn = token && JSON.parse(token);
    if (tkn && dayjs(tkn?.expires?.date).isAfter(dayjs())) {
      dispatch(UserDetails({navigate: false, isRedirect: false, token: tkn.uuid}))
      setLoading(false)
    } else {
      setLoading(false)
    }
  },[])

  return (
    <div className={ui.showMenu ? "app menu-open" : "app"} style={ui.showMenu ? {height: height} : {height: 'auto'}}>
      { loading ? <div className="app-loader"><Loading/></div> : <Routes /> }
    </div>
  );
}

export default App;
