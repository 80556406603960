import React, { useEffect, useState } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import './Login.scss';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { Login } from '../../features/user/authService';
import { useAppSelector } from '../../hooks';
import { selectAuthState } from '../../features/user/userSlice'
import IMG from './img/login-image.jpg';
import IMG_MOBILE from './img/login-image-mobile.jpg';
import EYE from './img/eye-no.svg';

interface loginProps {
  emailAddress: 'string';
  password: 'string';
}

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const isAuthenticated = useAppSelector(selectAuthState);
  const [showPass, setShowPass] = useState(false);

  useEffect(()=>{
    if (isAuthenticated) navigate("/dashboard", {replace: true})
  },[])

  const onSubmit = ({email, password}: FieldValues) => {
    dispatch(
      Login({ 
        credentials: {
          emailAddress: email, 
          password: password
        }, 
        navigate: navigate 
      })
    )
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  return (
    <PageLayout>
      <div className="page Login form-columns">
        <div className="image-column">
          <img className="form-image" src={IMG} />
        </div>
        <div className="form-column">
          <h1>Logowanie</h1>
          <img className="form-image-mobile" src={IMG_MOBILE} />
          <form>
            <div className="input-item">
              <label htmlFor="email">E-mail</label>
              <input placeholder='jan@example.pl' className={ errors.email && 'error-input' } type="text" id="email" autoComplete="username" {...register("email", {required: true, pattern:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></input>
              {errors.email && errors.email.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.email && errors.email.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <div className="input-item password-input">
              <div className="show-pass" onClick={()=>setShowPass(!showPass)}><img src={EYE} /></div>
              <label htmlFor="password">Hasło</label>
              <input className={ errors.password && 'error-input' } type={showPass ? "text" : "password"} id="password" autoComplete="current-password" {...register("password", {required: true})} ></input>
              {errors.password && errors.password.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.password && errors.password.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <div className="text-center margin-20 text-smaller">
              Nie pamiętasz hasła? <Link to="/przypomnienie-hasla">Zresetuj hasło</Link>
            </div>
            <Button onClick={ handleSubmit(onSubmit, onError) }>Zaloguj się</Button>
            <div className="text-center margin-20">
              Pierwszy raz w <strong>Bat Rep</strong>?<br/> 
              <Link to="/aktywacja">Aktywuj konto</Link>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
}

export default LoginPage
