import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Ranking.scss";
import DATA from "../../data/owner-results-date-sort.json";
import { ChartType } from "../Dashboard/DashboardBarChart";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectRankingGlobal, selectRankingRegion, selectUserRankingGlobal, selectUserRankingRegion } from "../../features/ranking/rankingSlice";
import { GetRankingGlobal, GetRankingRegion } from "../../features/ranking/rankingService";
import infoIcon from './img/info-icon.svg';
import dayjs from "dayjs";
import { ReactComponent as Arrow } from "./img/arrow.svg";
import { ReactComponent as ArrowNext } from "./img/arrow-next.svg";
import { selectToken, selectUserData } from "../../features/user/userSlice";
import { API_URL } from "../../fetch";
import NoProfilPic from "../../components/ProfilePic/no-profil-pic.svg";
import ProfilePic from "../../components/ProfilePic/ProfilePic";

function RankingRows({data, position}: any) {
  const token = useAppSelector(selectToken);
  
  return (
    <>
    { data.map((user:any, key:number) => {
      return(
        <div className={user.position === position ? "user-ranking-row orange" : "user-ranking-row" } key={'rank-'+key}>
          <div className="position">{ user.position }</div>
          <div className="name"><img src={user?.avatar ? `${API_URL}/file/serve/${user?.avatar}?token=${token?.uuid}` : NoProfilPic} />{ user.firstName }</div>
          <div className="value">{ user.counter }</div>
        </div>
      )})
    }
  </>
  )
}

function Ranking() {
  const [rankingType, setRankingType] = useState<any>("global")
  const [dateCounter, setDateCounter] = useState<any>(0)
  const dispatch = useAppDispatch();
  const rankingRegion = useAppSelector(selectRankingRegion);
  const rankingGlobal = useAppSelector(selectRankingGlobal);
  const userRegion = useAppSelector(selectUserRankingRegion);
  const userGlobal = useAppSelector(selectUserRankingGlobal);
  const user = useAppSelector(selectUserData);
  const rankings:any = {'region': { ranking: rankingRegion, user: userRegion }, 'global': { ranking: rankingGlobal, user: userGlobal }}

  useEffect(()=>{
    dispatch(GetRankingRegion(dayjs().locale('pl').add(dateCounter, 'month').format('YYYY-MM')))
    dispatch(GetRankingGlobal(dayjs().locale('pl').add(dateCounter, 'month').format('YYYY-MM')))
  }, [dateCounter])

  const text = {
    sales: {
      name: "Liczba sprzedaży",
      val: ""
    },
    referrals: {
      name: "Liczba poleceń",
      val: ""
    }
  }

  return (
    <PageLayout>
      <div className="page Ranking">
        <div className="rank-options">
          <h1>Ranking</h1>
          <div className="ranking-select">
            <div className={ rankingType === 'region' ? 'option active' : 'option' } onClick={()=>setRankingType('region')}>Region</div>
            <div className={ rankingType === 'global' ? 'option active' : 'option' } onClick={()=>setRankingType('global')}>Cała Polska</div>
          </div>
        </div>
        <div className="dropshadow-box ranking-container">
          <div className="rankign-head time-interval">
            <div className="DateSelect">
              <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
              <div className="date-range">{ dayjs().locale('pl').add(dateCounter, 'month').format('MMMM YYYY') }</div>
              <div className={dateCounter != 0 ? "next-date" : "next-date inactive"} onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
            </div>
          </div>
          <div className="rankign-body">
            <div className="user-profile">
              <div className="user-name">
                <ProfilePic />
                <p className="name">{ user?.firstName }</p>
              </div>
              <div className="user-place">
                <p>Twoja pozycja</p>
                <p>{ rankings[rankingType].user?.position != null ? rankings[rankingType].user?.position : '-' }</p>
              </div>
              <div className="user-points">
                <p>Twój wynik</p>
                <p>{ rankings[rankingType].user?.counter != null ? rankings[rankingType].user?.counter : '-' }</p>
              </div>
            </div>
            <div className="user-ranking">
              <div className="user-ranking-row user-ranking-head">
                <div>Pozycja</div>
                <div>Przedstawiciel</div>
                <div className="info-container">
                  Wynik 
                  <img src={infoIcon} className="info-icon" alt="więcej informacji"/>
                  <div className="info-modal">
                    <h4>Wynik</h4>
                    Wynik w Rankingu pokazuje tylko sprzedaż urządzeń w punktach sprzedaży, które są zarejestrowane 
                    w programie BAT Partners Detalist
                  </div>
                </div>
              </div>
              <RankingRows data={rankingType === "region" ? rankingRegion : rankingGlobal} position={rankings[rankingType].user?.position} />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Ranking;